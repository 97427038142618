import { abbreviateNumber } from './format-numbers';

export function abbreviateNumberRangeDisplayString(
  value_gte?: string,
  value_lte?: string,
  minLabel?: string,
  maxLabel?: string
) {
  let display = '';
  /** TODO: Confirm steps and desired display formatting for values (applying commas to values in the mean time) */
  if (value_gte && value_lte) {
    display = `${abbreviateNumber(value_gte)} - ${abbreviateNumber(value_lte)}`;
  } else if (value_gte) {
    display = `${minLabel} ${abbreviateNumber(value_gte)}`;
  } else if (value_lte) {
    display = `${maxLabel} ${abbreviateNumber(value_lte)}`;
  }
  return display;
}
