import { OutliersFilter as BaseOutliersFilter } from '@studio/components/filters';
import { PARAM_KEYS } from '@studio/features/projects/constants';
import {
  PROJECTS_FILTER_OUTLIER_OPEN,
  PROJECTS_FILTER_OUTLIER_CHANGE,
} from '@studio/features/projects/heap.constants';

export function OutlierFilterOld() {
  return (
    <BaseOutliersFilter
      minId={PARAM_KEYS.OUTLIER_INDEX_GTE}
      maxId={PARAM_KEYS.OUTLIER_INDEX_LTE}
      eventNames={{
        opened: PROJECTS_FILTER_OUTLIER_OPEN,
        changed: PROJECTS_FILTER_OUTLIER_CHANGE,
      }}
    />
  );
}
