import { useInfiniteQuery } from '@tanstack/react-query';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import { USE_QUERY_KEYS } from '@studio/hooks';
import { useActiveOrganizationId } from '@studio/stores';
import { fetchAiCategories } from '../requests/fetch-ai-categories';

export function useFetchAiCategories() {
  const channelUcid = useActiveChannelUcid();
  const orgId = useActiveOrganizationId();

  return useInfiniteQuery({
    queryKey: [USE_QUERY_KEYS.FETCH_AI_CATEGORIES, orgId, channelUcid],
    queryFn: ({ pageParam = 1 }) =>
      fetchAiCategories(pageParam as number, orgId, channelUcid),
    initialPageParam: 1,
    getNextPageParam: ({ page }) => page.next ?? null,
    getPreviousPageParam: ({ page }) => page.previous ?? null,
  });
}
