import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useGateValue } from '@statsig/react-bindings';
import { TOUR_IDS } from '@studio/features/tours/projects-tour/constants';
import { useParamStore } from '@studio/stores';
import { PROJECT_STATUS } from '@lib/types';
import { ToggleGroup, ToggleTabs } from '@lib/ui';
import { STATSIG_FEATURE_FLAGS } from '@lib/utils';
import {
  DEFAULT_SORT_VALUES,
  DEFAULT_VALUES,
  DEFAULT_VALUES_IDEA_BANK,
  PARAM_KEYS,
  PUBLISHED,
} from '../../constants';
import { getUserSortPref } from './filters/sort-select-old';
import { useProjectsHeaderAnalytics } from './use-projects-header-analytics';

export function StatusToggle() {
  const { t } = useTranslation();

  const ideaBankEnabled = useGateValue(STATSIG_FEATURE_FLAGS.STUDIO_IDEA_BANK);

  const projectsDefaultValues = ideaBankEnabled
    ? DEFAULT_VALUES_IDEA_BANK
    : DEFAULT_VALUES;
  const { params, setParams } = useParamStore(projectsDefaultValues);

  const { onProjectsPublishedToggle } = useProjectsHeaderAnalytics();

  let paramKey: string;
  let value: string;

  if (ideaBankEnabled) {
    paramKey = PARAM_KEYS.STATUS_IN;
    value = params[paramKey] ?? PROJECT_STATUS.IDEA;
  } else {
    paramKey = PARAM_KEYS.PUBLISHED;
    value = params[paramKey] ?? PUBLISHED.FALSE;
  }

  const onValueChange = useCallback(
    (value: string) => {
      // prevent toggling off the presently selected item
      if (!value.length) return;

      onProjectsPublishedToggle(value);

      let key = '';

      if (ideaBankEnabled) {
        key = PARAM_KEYS.STATUS_IN;
      } else {
        key = PARAM_KEYS.PUBLISHED;
      }

      let sortValue: string;

      switch (value) {
        case PUBLISHED.TRUE:
          sortValue = (getUserSortPref('published') ??
            DEFAULT_SORT_VALUES[PUBLISHED.TRUE]) as string;
          break;
        case PUBLISHED.FALSE:
          sortValue = (getUserSortPref('unpublished') ??
            DEFAULT_SORT_VALUES[PUBLISHED.FALSE]) as string;
          break;
        case PROJECT_STATUS.IDEA:
          sortValue = (getUserSortPref('idea') ??
            DEFAULT_SORT_VALUES[PROJECT_STATUS.IDEA]) as string;
          break;
        default:
          sortValue = DEFAULT_SORT_VALUES[PUBLISHED.FALSE];
      }

      if (ideaBankEnabled) {
        // TODO: Clean this up when launching the Idea Bank:
        // because of the messy way that concepts are being handled, we need to
        // clear the previous status param when toggling FROM ideas and clear
        // the previous published param when toggling TO ideas
        const status =
          params[PARAM_KEYS.STATUS_IN] === PROJECT_STATUS.IDEA
            ? {
                [PARAM_KEYS.STATUS_IN]: '',
              }
            : {};
        const published =
          value === PROJECT_STATUS.IDEA
            ? {
                [PARAM_KEYS.PUBLISHED]: '',
              }
            : {};

        setParams({
          ...projectsDefaultValues,
          ...published,
          ...status,
          [key]: value,
          [PARAM_KEYS.SORT_BY]: sortValue,
        });
      } else {
        setParams({
          ...projectsDefaultValues,
          [key]: value,
          [PARAM_KEYS.SORT_BY]: sortValue,
        });
      }
    },
    [params, setParams, onProjectsPublishedToggle]
  );

  return (
    <div>
      {ideaBankEnabled ? (
        <ToggleTabs.Root
          fullWidth
          value={value}
          onValueChange={onValueChange}
          data-tour={TOUR_IDS.PROJECTS_TOGGLE}
        >
          <ToggleTabs.Item id={PROJECT_STATUS.IDEA} value={PROJECT_STATUS.IDEA}>
            {t('Ideas')}
          </ToggleTabs.Item>
          <ToggleTabs.Item
            id={PROJECT_STATUS.IN_DEVELOPMENT}
            value={PROJECT_STATUS.IN_DEVELOPMENT}
          >
            {t('In Development')}
          </ToggleTabs.Item>
          <ToggleTabs.Item
            id={PROJECT_STATUS.PUBLISHED}
            value={PROJECT_STATUS.PUBLISHED}
          >
            {t('Published')}
          </ToggleTabs.Item>
        </ToggleTabs.Root>
      ) : (
        <ToggleGroup.Root
          type="single"
          variant="light"
          size="sm"
          value={value}
          onValueChange={onValueChange}
          data-tour={TOUR_IDS.PROJECTS_TOGGLE}
        >
          <ToggleGroup.Item id={PUBLISHED.FALSE} value={PUBLISHED.FALSE}>
            {t('In Progress')}
          </ToggleGroup.Item>
          <ToggleGroup.Item
            id={PUBLISHED.TRUE}
            value={PUBLISHED.TRUE}
            data-tour={TOUR_IDS.PROJECTS_TOGGLE_PUBLISHED}
          >
            {t('Published')}
          </ToggleGroup.Item>
        </ToggleGroup.Root>
      )}
    </div>
  );
}
