import { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { Flex } from '@lib/ui';
import * as Styles from './page-header.css';

type Props = PropsWithChildren & {
  className?: string;
};

const Container = ({ children, className }: Props) => {
  return (
    <header className={clsx(Styles.container, className)}>{children}</header>
  );
};

const TitleRow = ({ children, className }: Props) => {
  return <div className={clsx(Styles.titleRow, className)}>{children}</div>;
};

const TabsContainer = ({ children, className }: Props) => {
  return (
    <div className={clsx(Styles.tabsContainer, className)}>{children}</div>
  );
};

// TODO: remove this once idea bank is launched and move to TabsContainer
const TabsContainerWithBorder = ({ children, className }: Props) => {
  return (
    <div className={clsx(Styles.tabsContainer, Styles.tabsBorder, className)}>
      {children}
    </div>
  );
};

const ChipsRow = ({ children, className }: Props) => {
  return <div className={clsx(Styles.chipsRow, className)}>{children}</div>;
};

const Actions = ({ children, className }: Props) => {
  return <Flex className={clsx(Styles.actions, className)}>{children}</Flex>;
};

const Filters = ({ children, className }: Props) => {
  return <Flex className={clsx(Styles.filters, className)}>{children}</Flex>;
};

export {
  Actions,
  ChipsRow,
  Container,
  Filters,
  TabsContainer,
  TabsContainerWithBorder,
  TitleRow,
};
