import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function UndoIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={clsx(iconStyles, className)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.95059 6.23257C10.5391 5.64952 10.5435 4.69978 9.96046 4.11128C9.3774 3.52277 8.42766 3.51835 7.83916 4.10141L1.44428 10.4371C1.15996 10.7188 1 11.1024 1 11.5027C1 11.9029 1.15996 12.2865 1.44428 12.5682L7.83915 18.9039C8.42766 19.487 9.3774 19.4825 9.96045 18.894C10.5435 18.3055 10.5391 17.3558 9.95059 16.7727L5.97437 12.8333H20.8333C25.0006 12.8333 28 15.7389 28 19.5837C28 21.5421 27.282 22.9063 26.1388 23.8161C24.9507 24.7617 23.1503 25.3333 20.8333 25.3333H16.0833C15.2549 25.3333 14.5833 26.0049 14.5833 26.8333C14.5833 27.6618 15.2549 28.3333 16.0833 28.3333H20.8333C23.5997 28.3333 26.1327 27.6551 28.007 26.1634C29.9263 24.6359 31 22.3752 31 19.5837C31 13.9284 26.4994 9.83333 20.8333 9.83333H6.31618L9.95059 6.23257Z"
        fill="currentColor"
      />
    </svg>
  );
}
