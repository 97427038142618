export const HOME_CLICK = 'home_click';
export const HOME_FREEFORM_BRAINSTORM_KEYWORDS_CHANGE = 'home_freeform_brainstorm_keywords_change';
export const HOME_FREEFORM_BRAINSTORM_CLICK = 'home_freeform_brainstorm_click';
export const HOME_BRAINSTORM_KEYWORDS_CHANGE = 'home_brainstorm_keywords_change';
export const HOME_BRAINSTORM_CLICK = 'home_brainstorm_click';
export const HOME_NEW_PROJECT_CLICK = 'home_new_project_click';
export const HOME_OUTLIERS_SEE_MORE_CLICK = 'home_outliers_see_more_click';
export const HOME_PROFILE_DROPDOWN_CLICK = 'home_profile_dropdown_click';
export const HOME_OUTLIER_BRAINSTORM_CLICK = 'home_outlier_brainstorm_click';
export const HOME_DROPDOWN_SWITCH_CHANNEL_CLICK = 'home_dropdown_switch_channel_click';
export const HOME_DROPDOWN_ADD_CHANNEL_CLICK = 'home_dropdown_add_channel_click';
export const HOME_SWITCH_CHANNEL_CONTINUE_CLICK = 'home_switch_channel_continue_click';
export const HOME_PROJECTS_IDEAS_FOR_YOU_TAB_CLICK = 'home_projects_ideas_for_you_tab_click';
export const HOME_PROJECTS_ONGOING_PROJECTS_TAB_CLICK = 'home_projects_ongoing_projects_tab_click';
export const HOME_PROJECTS_IDEAS_FOR_YOU_SAVE_CLICK = 'home_projects_ideas_for_you_save_click';
export const HOME_PROJECTS_IDEAS_FOR_YOU_DISMISS_CLICK = 'home_projects_ideas_for_you_dismiss_click';
export const HOME_PROJECTS_IDEAS_FOR_YOU_UNDO_DISMISS_CLICK = 'home_projects_ideas_for_you_undo_dismiss';
export const HOME_PROJECTS_IDEAS_FOR_YOU_IDEA_VIEWED = 'home_projects_ideas_for_you_idea_viewed';
export const HOME_PROJECTS_SEE_MORE_CLICK = 'home_projects_see_more_click';
export const HOME_PROJECTS_SEE_MORE_CLICK_IN_DEV = 'home_projects_see_more_click_in_dev';
export const HOME_PROJECTS_SEE_MORE_CLICK_IDEA_BANK = 'home_projects_see_more_click_idea_bank';
export const HOME_PROJECTS_SEE_MORE_CLICK_PUBLISHED = 'home_projects_see_more_click_published';
export const HOME_PUBLISHED_SEE_MORE_CLICK = 'home_published_see_more_click';
export const HOME_IDEATION_BUILD_A_PROJECT_TAB_CLICK = 'home_ideation_build_a_project_tab_click';
export const HOME_IDEATION_IDEA_CANVAS_TAB_CLICK = 'home_ideation_idea_canvas_tab_click';
