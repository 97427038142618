import { t } from 'i18next';
import { DateFilter } from '@studio/components';
import { ParamId } from '@studio/components/filters/types';
import { DISPLAY_FORMAT } from '@studio/features/projects';
import * as EVENTS from '@studio/features/projects/heap.constants';
import { useParamStore } from '@studio/stores';
import { Form } from '@lib/ui';

type Props = {
  minId: ParamId;
  maxId: ParamId;
};
export function PublishDateFilterInput(props: Props) {
  const { minId, maxId } = props;
  const { params, removeParamTracked, setParamsTracked } = useParamStore();

  const setAndTrackParams = (value: { [key: string]: string }) => {
    setParamsTracked(EVENTS.PROJECTS_FILTER_PUBLISH_DATE_CHANGE, value);
  };

  const removeAndTrackParams = (value: string) => {
    removeParamTracked(EVENTS.PROJECTS_FILTER_PUBLISH_DATE_CHANGE, value);
  };

  return (
    <Form.Root>
      <DateFilter
        minLabel={t('Start')}
        maxLabel={t('End')}
        minId={minId}
        maxId={maxId}
        displayFormat={DISPLAY_FORMAT}
        params={params}
        setParams={setAndTrackParams}
        removeParam={removeAndTrackParams}
        closeOnSelection={true}
      />
    </Form.Root>
  );
}
