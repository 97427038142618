import clsx from 'clsx';
import { AiCategory } from '@studio/features/projects/types';
import { InputChip } from '@lib/ui';
import * as Styles from './ai-category-chip.css';

type Props = {
  category: AiCategory;
  selected: boolean;
  onClick: (category: AiCategory) => void;
};

export function AiCategoryChip({ category, selected, onClick }: Props) {
  return (
    <InputChip
      pill
      condensed
      noTruncateLabel
      label={category.name}
      className={clsx(Styles.container, !selected && Styles.dashed)}
      fill={selected ? 'solid' : 'ghost'}
      onClick={() => onClick(category)}
    />
  );
}
