import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useParamStore } from '@studio/stores';
import { Flex, Icons } from '@lib/ui';
import * as Styles from './scroll-wrapper.css';

type Props = PropsWithChildren & {
  fadeLeftStyle?: string;
  fadeRightStyle?: string;
  arrowStyles?: string;
};

export function ScrollWrapper({
  children,
  fadeLeftStyle,
  fadeRightStyle,
  arrowStyles,
}: Props) {
  const { params } = useParamStore();
  const { t } = useTranslation();
  const flexRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const checkOverflow = () => {
    const current = flexRef.current;

    if (!current) {
      return;
    }

    const isOverflowingRight =
      current.scrollLeft + current.clientWidth < current.scrollWidth;

    setShowRightArrow(isOverflowingRight);
    setShowLeftArrow(current.scrollLeft > 0);
  };

  useEffect(() => {
    checkOverflow();
  }, [params]);

  useEffect(() => {
    const currentRef = flexRef.current;
    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    const observer = new ResizeObserver(checkOverflow);

    if (currentRef) {
      observer.observe(currentRef);
    }

    currentRef?.addEventListener('scroll', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
      currentRef?.removeEventListener('scroll', checkOverflow);

      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  const handleScrollClick = (value: number) => {
    flexRef.current?.scrollBy({
      left: value,
      behavior: 'smooth',
    });
  };

  return (
    <div className={Styles.scrollWrapper}>
      {showLeftArrow && (
        <>
          <div
            className={clsx(Styles.fadeOverlay, Styles.fadeLeft, fadeLeftStyle)}
          />
          <button
            className={clsx(Styles.scrollArrows, Styles.leftArrow, arrowStyles)}
            onClick={() => handleScrollClick(-100)}
            aria-label={t('Scroll Left')}
          >
            <Icons.ChevronLeftIcon aria-hidden />
          </button>
        </>
      )}
      <Flex style={{ overflowX: 'auto' }} ref={flexRef}>
        {children}
      </Flex>
      {showRightArrow && (
        <>
          <div
            className={clsx(
              Styles.fadeOverlay,
              Styles.fadeRight,
              fadeRightStyle
            )}
          />
          <button
            className={clsx(
              Styles.scrollArrows,
              Styles.rightArrow,
              arrowStyles
            )}
            onClick={() => handleScrollClick(100)}
            aria-label={t('Scroll Right')}
          >
            <Icons.ChevronRightIcon aria-hidden />
          </button>
        </>
      )}
    </div>
  );
}
