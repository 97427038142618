import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeatureGate } from '@statsig/react-bindings';
import {
  DEFAULT_SORT_VALUES,
  PARAM_KEYS,
  PUBLISHED,
  SORT_ORDER,
} from '@studio/features/projects';
import * as EVENTS from '@studio/features/projects/heap.constants';
import { trackEvent } from '@studio/lib/heap';
import { useParamStore } from '@studio/stores';
import { getLocalStorage } from '@studio/utils';
import { PROJECT_STATUS } from '@lib/types';
import { Button, Icons, Select, Text } from '@lib/ui';
import { STATSIG_FEATURE_FLAGS } from '@lib/utils';

const PUBLISHED_PROJECT_SORT_LS_KEY = 'projects.sort.published';
const UNPUBLISHED_PROJECT_SORT_LS_KEY = 'projects.sort.unpublished';
const IDEA_SORT_LS_KEY = 'projects.sort.idea';
const { getItem, setItem } = getLocalStorage();

export const getUserSortPref = (view: 'published' | 'unpublished' | 'idea') => {
  let key: string;

  switch (view) {
    case 'published':
      key = PUBLISHED_PROJECT_SORT_LS_KEY;
      break;
    case 'unpublished':
      key = UNPUBLISHED_PROJECT_SORT_LS_KEY;
      break;
    case 'idea':
      key = IDEA_SORT_LS_KEY;
      break;
  }

  return getItem<typeof SORT_ORDER>(key);
};

const SelectItem = ({
  children,
  ...props
}: {
  children: string;
  value: string;
}) => {
  return (
    <Select.Item {...props}>
      <Select.ItemText>{children}</Select.ItemText>
      <Select.ItemIndicator>
        <Icons.CheckmarkIcon aria-hidden />
      </Select.ItemIndicator>
    </Select.Item>
  );
};

export function SortSelectOld() {
  const { t } = useTranslation();
  const { params, setParams } = useParamStore();
  const { value: ideaBankEnabled } = useFeatureGate(
    STATSIG_FEATURE_FLAGS.STUDIO_IDEA_BANK
  );

  const isIdeaBank = params[PARAM_KEYS.STATUS_IN] === 'idea';
  const sortValue = params[PARAM_KEYS.SORT_BY];
  let defaultSortValue: string;
  let lsKey: string;

  if (ideaBankEnabled) {
    if (isIdeaBank) {
      lsKey = IDEA_SORT_LS_KEY;
      defaultSortValue = DEFAULT_SORT_VALUES[PROJECT_STATUS.IDEA];
    } else {
      const isPublished = params[PARAM_KEYS.PUBLISHED] === 'true';

      lsKey = isPublished
        ? PUBLISHED_PROJECT_SORT_LS_KEY
        : UNPUBLISHED_PROJECT_SORT_LS_KEY;

      defaultSortValue =
        DEFAULT_SORT_VALUES[isPublished ? PUBLISHED.TRUE : PUBLISHED.FALSE];
    }
  } else {
    // repetitive but easier to clean up this logic once the Idea Bank feature gate is removed
    const isPublished = params[PARAM_KEYS.PUBLISHED] === 'true';

    lsKey = isPublished
      ? PUBLISHED_PROJECT_SORT_LS_KEY
      : UNPUBLISHED_PROJECT_SORT_LS_KEY;

    defaultSortValue =
      DEFAULT_SORT_VALUES[isPublished ? PUBLISHED.TRUE : PUBLISHED.FALSE];
  }

  useEffect(() => {
    // there's a timing issue with setting a param before the defaults have registered
    if (!Object.values(params).length) return;

    // if the sort value spec'd in the URL is anything but the default, ignore user pref
    const userPref = getItem<string>(lsKey);
    const sortParam = params[PARAM_KEYS.SORT_BY];
    const isParamDefault = sortParam === defaultSortValue;

    if (userPref && isParamDefault) {
      setParams({ [PARAM_KEYS.SORT_BY]: userPref });
    }
  }, [params]);

  let items: {
    label: string;
    value: string;
    eventLabel: string;
  }[] = [];

  if (ideaBankEnabled && isIdeaBank) {
    items = [
      {
        label: t('Created Date newest to oldest'),
        value: SORT_ORDER.CREATED_ASC,
        eventLabel: EVENTS.PROJECTS_SORT_CREATED_DATE_NEWEST_TO_OLDEST_CLICK,
      },
      {
        label: t('Created Date oldest to newest'),
        value: SORT_ORDER.CREATED_DESC,
        eventLabel: EVENTS.PROJECTS_SORT_CREATED_DATE_OLDEST_TO_NEWEST_CLICK,
      },
      {
        label: t('Last Edited newest to oldest'),
        value: SORT_ORDER.EDITED_ASC,
        eventLabel: EVENTS.PROJECTS_SORT_LAST_EDITED_NEWEST_TO_OLDEST_CLICK,
      },
      {
        label: t('Last Edited oldest to newest'),
        value: SORT_ORDER.EDITED_DESC,
        eventLabel: EVENTS.PROJECTS_SORT_LAST_EDITED_OLDEST_TO_NEWEST_CLICK,
      },
      {
        label: t('My Score lowest to highest'),
        value: SORT_ORDER.RANK_ASC,
        eventLabel: EVENTS.PROJECTS_SORT_USER_RANK_LOWEST_TO_HIGHEST_CLICK,
      },
      {
        label: t('My Score highest to lowest'),
        value: SORT_ORDER.RANK_DESC,
        eventLabel: EVENTS.PROJECTS_SORT_USER_RANK_HIGHEST_TO_LOWEST_CLICK,
      },
      {
        label: t('Title Score lowest to highest'),
        value: SORT_ORDER.SPOTTERS_ASC,
        eventLabel: EVENTS.PROJECTS_SORT_SPOTTER_SCORE_LOWEST_TO_HIGHEST_CLICK,
      },
      {
        label: t('Title Score highest to lowest'),
        value: SORT_ORDER.SPOTTERS_DESC,
        eventLabel: EVENTS.PROJECTS_SORT_SPOTTER_SCORE_HIGHEST_TO_LOWEST_CLICK,
      },
    ];
  } else {
    items = [
      {
        label: t('Last Edited oldest to newest'),
        value: SORT_ORDER.EDITED_DESC,
        eventLabel: EVENTS.PROJECTS_SORT_LAST_EDITED_OLDEST_TO_NEWEST_CLICK,
      },
      {
        label: t('Last Edited newest to oldest'),
        value: SORT_ORDER.EDITED_ASC,
        eventLabel: EVENTS.PROJECTS_SORT_LAST_EDITED_NEWEST_TO_OLDEST_CLICK,
      },
      {
        label: t('Publish Date oldest to newest'),
        value: SORT_ORDER.PUBLISHED_DESC,
        eventLabel: EVENTS.PROJECTS_SORT_PUBLISH_DATE_OLDEST_TO_NEWEST_CLICK,
      },
      {
        label: t('Publish Date newest to oldest'),
        value: SORT_ORDER.PUBLISHED_ASC,
        eventLabel: EVENTS.PROJECTS_SORT_PUBLISH_DATE_NEWEST_TO_OLDEST_CLICK,
      },
      {
        label: t('Created Date oldest to newest'),
        value: SORT_ORDER.CREATED_DESC,
        eventLabel: EVENTS.PROJECTS_SORT_CREATED_DATE_OLDEST_TO_NEWEST_CLICK,
      },
      {
        label: t('Created Date newest to oldest'),
        value: SORT_ORDER.CREATED_ASC,
        eventLabel: EVENTS.PROJECTS_SORT_CREATED_DATE_NEWEST_TO_OLDEST_CLICK,
      },
      {
        label: t('Project Name A - Z'),
        value: SORT_ORDER.NAME_ASC,
        eventLabel: EVENTS.PROJECTS_SORT_TITLE_A_Z_CLICK,
      },
      {
        label: t('Project Name Z - A'),
        value: SORT_ORDER.NAME_DESC,
        eventLabel: EVENTS.PROJECTS_SORT_TITLE_Z_A_CLICK,
      },
    ];
  }

  const getEventLabelValue = (sortValue: string) => {
    const matchedItem = items.find((item) => item.value === sortValue);
    return matchedItem ? matchedItem.eventLabel : '';
  };

  const handleChange = (value: string) => {
    trackEvent(getEventLabelValue(value));
    setParams({ [PARAM_KEYS.SORT_BY]: value });
    setItem(lsKey, value);
  };

  return (
    <Select.Root
      value={sortValue}
      onValueChange={handleChange}
      onOpenChange={(value) => {
        if (value) {
          trackEvent(EVENTS.PROJECTS_SORT_CLICK);
        }
      }}
    >
      <Select.Trigger
        asChild
        defaultValue={t('Last Viewed')}
        label={t('Sort by')}
      >
        <Button
          variant="subtle"
          size="sm"
          adornmentStart={<Icons.SortArrowsIcon aria-hidden />}
        >
          <Text>{t('Sort')}</Text>
        </Button>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content align="end">
          <Select.Viewport>
            <Select.Group>
              {items.map((item) => (
                <SelectItem
                  key={item.value}
                  children={t(item.label)}
                  value={item.value}
                />
              ))}
            </Select.Group>
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
}
