import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AllowFilter } from '@studio/components';
import { PARAM_KEYS, ACCESS } from '@studio/features/projects/constants';
import * as EVENTS from '@studio/features/projects/heap.constants';
import { trackEvent } from '@studio/lib/heap';
import { useParamStore } from '@studio/stores';

export function AccessFilterInput() {
  const { t } = useTranslation();
  const { params, setParams } = useParamStore();

  const accessFilterItems = [
    {
      id: `${PARAM_KEYS.ACCESS}-${ACCESS.ALL}`,
      label: t('All'),
      value: ACCESS.ALL,
    },
    {
      id: `${PARAM_KEYS.ACCESS}-${ACCESS.USER_OWNED}`,
      label: t('Private'),
      value: ACCESS.USER_OWNED,
    },
    {
      id: `${PARAM_KEYS.ACCESS}-${ACCESS.SHARED}`,
      label: t('Team'),
      value: ACCESS.SHARED,
    },
  ];

  const [selectedValue, setSelectedValue] = useState<string>(
    params[PARAM_KEYS.ACCESS] || accessFilterItems[0].value
  );

  const handleChange = (param: { [key: string]: string }) => {
    trackEvent(EVENTS.PROJECTS_FILTER_ACCESS_CHANGE, {
      old_value: selectedValue,
      new_value: param.access,
    });
    setSelectedValue(param.access);
    setParams(param);
  };

  return (
    <AllowFilter
      id={PARAM_KEYS.ACCESS}
      value={selectedValue}
      setParams={handleChange}
      items={accessFilterItems}
    />
  );
}
