import { api } from '@studio/lib';
import { AiCategoriesAPIResponse } from '../types';

export const fetchAiCategories = async (
  page: number,
  orgId: string,
  channelUcid: string
) => {
  const response = await api.bowser.get<AiCategoriesAPIResponse>(
    `/api/orgs/${orgId}/channels/${channelUcid}/ai-categories?page=${page}`
  );
  return response;
};
