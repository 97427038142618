import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function SortArrowsIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(iconStyles, className)}
      {...props}
    >
      <path
        d="M15.1667 4.3335V17.6668M15.1667 17.6668L11.8333 14.3335M15.1667 17.6668L18.5 14.3335M6.83333 17.6668V4.3335M6.83333 4.3335L3.5 7.66683M6.83333 4.3335L10.1667 7.66683"
        stroke="currentColor"
        strokeWidth="1.85714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
