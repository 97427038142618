import { useRef } from 'react';
import { useIsOnScreen } from '@lib/ui';
import * as Styles from './tags-select.css';

interface LoadMoreTagsProps {
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
}

export const LoadMoreTags = (props: LoadMoreTagsProps) => {
  const loadMoreRef = useRef<HTMLDivElement>(null);

  useIsOnScreen(loadMoreRef, () => {
    if (!props.isFetchingNextPage && props.hasNextPage) {
      props.fetchNextPage();
    }
  });

  return <div ref={loadMoreRef} className={Styles.trigger} />;
};
