export const OUTLIERS_FILTER_SOURCE_OPEN = 'outlier_filter_source_open';
export const OUTLIERS_FILTER_INDEX_OPEN = 'outlier_filter_index_open';
export const OUTLIERS_FILTER_UPLOADED_OPEN = 'outlier_filter_uploaded_open';
export const OUTLIERS_FILTER_SUBS_OPEN = 'outlier_filter_subs_open';
export const OUTLIERS_FILTER_VIDEO_VIEWS_OPEN = 'outlier_filter_video_views_open';
export const OUTLIERS_FILTER_VIDEO_DURATION_OPEN = 'outlier_filter_video_duration_open';
export const OUTLIERS_FILTER_MADE4KIDS_OPEN = 'outlier_filter_made4kids_open';
export const OUTLIERS_FILTER_MORE_OPEN = 'outlier_filter_more_open';
export const OUTLIERS_FILTER_SOURCE_CHANGE = 'outlier_filter_source_change';
export const OUTLIERS_FILTER_INDEX_CHANGE = 'outlier_filter_index_change';
export const OUTLIERS_FILTER_UPLOADED_CHANGE = 'outlier_filter_uploaded_change';
export const OUTLIERS_FILTER_SUBS_CHANGE = 'outlier_filter_subs_change';
export const OUTLIERS_FILTER_VIDEO_VIEWS_CHANGE = 'outlier_filter_videoviews_change';
export const OUTLIERS_FILTER_VIDEO_DURATION_CHANGE = 'outlier_filter_videoduration_change';
export const OUTLIERS_FILTER_MADE4KIDS_CHANGE = 'outlier_filter_made4kids_change';
export const OUTLIERS_FILTER_SEARCH_CHANGE = 'outlier_filter_search_change';
export const OUTLIERS_FILTER_RESET = 'outlier_filter_reset';
export const OUTLIERS_FAVOURITE_BOARD_OPEN = 'favourite_board_open';
export const OUTLIERS_LAYOUT_TOGGLE_CLICK = 'outlier_layout_toggle';
export const OUTLIERS_CHANNEL_SORT_CLICK = 'outlier_gridsort_channel_sort_click';
export const OUTLIERS_VIDEO_SORT_CLICK = 'outlier_gridsort_video_sort_click';
export const OUTLIERS_OUTLIER_INDEX_SORT_CLICK = 'outlier_gridsort_outlierindex_sort_click';
export const OUTLIERS_UPLOAD_SORT_CLICK = 'outlier_gridsort_upload_sort_click';
export const OUTLIERS_VIEWS_SORT_CLICK = 'outlier_gridsort_views_sort_click';
export const OUTLIERS_FILTER_PRESET_TOGGLE = 'outlier_preset_toggle';
export const OUTLIERS_FILTERS_MENU_RESET_ALL_CLICK = 'outlier_filters_menu_reset_all_click';
export const OUTLIERS_RESET_FILTER_CLICK = 'outlier_reset_filter_click';
export const OUTLIERS_FILTERS_MENU_TOGGLE = 'outlier_filter_menu_toggle';

export const OUTLIER_VIDEO_TYPE_TOGGLE_CLICK = 'outlier_video_type_toggle_click';
export const OUTLIER_VIDEO_CARD_CLICK = 'outlier_video_card_click';
export const OUTLIER_VIDEO_HOVER_BRAINSTORM_CLICK = 'outlier_video_hover_brainstorm_click';
export const OUTLIER_VIDEO_HOVER_FOLLOW_CLICK = 'outlier_video_hover_follow_click';
export const OUTLIER_VIDEO_HOVER_FAVOURITE_CLICK = 'outlier_video_hover_favourite_click';
export const OUTLIER_VIDEO_DETAIL_BRAINSTORM_CLICK = 'outlier_video_detail_brainstorm_click';
export const OUTLIER_VIDEO_DETAIL_FOLLOW_CHANNEL_CLICK = 'outlier_video_detail_follow_channel_click';
export const OUTLIER_VIDEO_DETAIL_SAVE_CLICK = 'outlier_video_detail_save_click';
export const OUTLIER_VIDEO_DETAIL_CLOSE_CLICK = 'outlier_video_detail_close_click';

// TODO: To be deleted once we turn on general availability for this gate:
// https://console.statsig.com/7fyNhUMuM2CYq5H8ExnHUz/gates/outliers_filter_presets
export const OUTLIERS_LIST_CHANNEL_HEADER_SORT_CLICK = 'outlier_list_channelheader_sort_click';
export const OUTLIERS_LIST_SUBS_HEADER_SORT_CLICK = 'outlier_list_subsheader_sort_click';
export const OUTLIERS_LIST_VIDEO_HEADER_SORT_CLICK = 'outlier_list_videoheader_sort_click';
export const OUTLIERS_LIST_OUTLIERS_INDEX_HEADER_SORT_CLICK = 'outlier_list_outlierindexheader_sort_click';
export const OUTLIERS_LIST_UPLOAD_HEADER_SORT_CLICK = 'outlier_list_uploadheader_sort_click';
export const OUTLIERS_LIST_V7_VIEWS_HEADER_SORT_CLICK = 'outlier_list_v7viewsheader_sort_click';
export const OUTLIERS_LIST_VIEWS_HEADER_SORT_CLICK = 'outlier_list_viewsheader_sort_click';
export const OUTLIERS_LIST_CATEGORY_HEADER_SORT_CLICK = 'outlier_list_categoryheader_sort_click';
export const OUTLIERS_LIST_DURATION_HEADER_SORT_CLICK = 'outlier_list_durationheader_sort_click';
export const OUTLIERS_ADD_VIDEO_TO_FAVORITES_CLICK = 'outlier_add_video_to_favourite_click';
export const OUTLIERS_ADD_CHANNEL_TO_FAVORITES_CLICK = 'outlier_add_channel_to_favourite_click';
export const OUTLIERS_REMOVE_VIDEO_FROM_FAVORITES_CLICK = 'outlier_remove_video_to_favourite_click';
export const OUTLIERS_REMOVE_CHANNEL_FROM_FAVORITES_CLICK = 'outlier_remove_channel_to_favourite_click';
export const OUTLIERS_VIDEO_LINK_TO_YOUTUBE_CLICK = 'outlier_video_link_to_youtube_click';
export const OUTLIERS_CHANNEL_LINK_TO_YOUTUBE_CLICK = 'outlier_channel_link_to_youtube_click';

export const OUTLIER_DEFAULT_TAB_SELECT = 'outliers_default_tab_select';
export const OUTLIER_TABS_AUDIENCE_CLICK = 'Outlier_tabs_audience_click';
export const OUTLIER_TABS_NEWEST_CLICK = 'Outlier_tabs_newest_click';
export const OUTLIER_TABS_FOLLOWED_CLICK = 'Outlier_tabs_followed_click';
export const OUTLIER_TABS_MYVIDEOS_CLICK = 'Outlier_tabs_myvideos_click';

export const OUTLIER_TOGGLE_VIDEO_CLICK = 'Outlier_toggle_video_click';
export const OUTLIER_TOGGLE_SHORTS_CLICK = 'Outlier_toggle_shorts_click';
export const OUTLIER_TOGGLE_GRIDVIEW_CLICK = 'Outlier_toggle_gridview_click';
export const OUTLIER_TOGGLE_TABLEVIEW_CLICK = 'Outlier_toggle_tableview_click';

export const OUTLIER_FILTER_SORT_CLICK = 'outlier_filter_sort_click';
export const OUTLIER_FILTER_SORT_UPLOAD_DATE_NEW_CLICK = 'outlier_filter_sort_upload_date_new_click';
export const OUTLIER_FILTER_SORT_UPLOAD_DATE_OLD_CLICK = 'outlier_filter_sort_upload_date_old_click';
export const OUTLIER_FILTER_SORT_VIEWS_HIGH_CLICK = 'outlier_filter_sort_views_high_click';
export const OUTLIER_FILTER_SORT_VIEWS_LOW_CLICK = 'outlier_filter_sort_views_low_click';
export const OUTLIER_FILTER_SORT_INDEX_HIGH_CLICK = 'outlier_filter_sort_index_high_click';
export const OUTLIER_FILTER_SORT_INDEX_LOW_CLICK = 'outlier_filter_sort_index_low_click';

export const OUTLIER_FILTER_INDEX_ADD_CLICK = 'outlier_filter_index_add_click';
export const OUTLIER_FILTER_INDEX_SELECT_CLICK = 'outlier_filter_index_select_click';
export const OUTLIER_FILTER_INDEX_REMOVE_CLICK = 'outlier_filter_index_remove_click';
export const OUTLIER_FILTER_INDEX_APPLY_CLICK = 'outlier_filter_index_apply_click';
export const OUTLIER_FILTER_INDEX_MIN_UPDATED = 'outlier_filter_index_min_updated';
export const OUTLIER_FILTER_INDEX_MAX_UPDATED = 'outlier_filter_index_max_updated';

export const OUTLIER_FILTER_DATE_ADD_CLICK = 'outlier_filter_date_add_click';
export const OUTLIER_FILTER_DATE_SELECT_CLICK = 'outlier_filter_date_select_click';
export const OUTLIER_FILTER_DATE_REMOVE_CLICK = 'outlier_filter_date_remove_click';
export const OUTLIER_FILTER_DATE_APPLY_CLICK = 'outlier_filter_date_apply_click';
export const OUTLIER_FILTER_DATE_STARTDATE_UPDATED = 'outlier_filter_date_startdate_updated';
export const OUTLIER_FILTER_DATE_ENDDATE_UPDATED = 'outlier_filter_date_enddate_updated';

export const OUTLIER_FILTER_SUBS_ADD_CLICK = 'outlier_filter_subs_add_click';
export const OUTLIER_FILTER_SUBS_SELECT_CLICK = 'outlier_filter_subs_select_click';
export const OUTLIER_FILTER_SUBS_REMOVE_CLICK = 'outlier_filter_subs_remove_click';
export const OUTLIER_FILTER_SUBS_10K_CLICK = 'outlier_filter_subs_10k_click';
export const OUTLIER_FILTER_SUBS_100K_CLICK = 'outlier_filter_subs_100k_click';
export const OUTLIER_FILTER_SUBS_1M_CLICK = 'outlier_filter_subs_1m_click';
export const OUTLIER_FILTER_SUBS_1M_UP_CLICK = 'outlier_filter_subs_1m+_click';

export const OUTLIER_FILTER_VIEWS_ADD_CLICK = 'outlier_filter_views_add_click';
export const OUTLIER_FILTER_VIEWS_SELECT_CLICK = 'outlier_filter_views_select_click';
export const OUTLIER_FILTER_VIEWS_REMOVE_CLICK = 'outlier_filter_views_remove_click';
export const OUTLIER_FILTER_VIEWS_10K_CLICK = 'outlier_filter_views_10k_click';
export const OUTLIER_FILTER_VIEWS_100K_CLICK = 'outlier_filter_views_100k_click';
export const OUTLIER_FILTER_VIEWS_500K_CLICK = 'outlier_filter_views_500k_click';
export const OUTLIER_FILTER_VIEWS_1M_CLICK = 'outlier_filter_views_1m_click';
export const OUTLIER_FILTER_VIEWS_1M_UP_CLICK = 'outlier_filter_views_1m+_click';

export const OUTLIER_FILTER_DURATION_ADD_CLICK = 'outlier_filter_duration_add_click';
export const OUTLIER_FILTER_DURATION_SELECT_CLICK = 'outlier_filter_duration_select_click';
export const OUTLIER_FILTER_DURATION_REMOVE_CLICK = 'outlier_filter_duration_remove_click';
export const OUTLIER_FILTER_DURATION_4MINS_CLICK = 'outlier_filter_duration_4mins_click';
export const OUTLIER_FILTER_DURATION_20MINS_CLICK = 'outlier_filter_duration_20mins_click';
export const OUTLIER_FILTER_DURATION_20MINS_UP_CLICK = 'outlier_filter_duration_20mins+_click';

export const OUTLIER_FILTER_KIDS_CLICK = 'outlier_filter_kids_click';
export const OUTLIER_FILTER_KIDS_HIDEKIDS_CLICK = 'outlier_filter_kids_hidekids_click';
export const OUTLIER_FILTER_KIDS_ONLYKIDS_CLICK = 'outlier_filter_kids_onlykids_click';
export const OUTLIER_FILTER_KIDS_ALLCONTENT_CLICK = 'outlier_filter_kids_allcontent_click';

export const OUTLIER_FILTER_RESET_CLICK = 'outlier_filter_reset_click';
