import { useTranslation } from 'react-i18next';
import { useGateValue } from '@statsig/react-bindings';
import { VideoDurationFilterInput } from '@studio/components/filters/inputs';
import { getDirtyFiltersCount } from '@studio/components/filters/utils';
import {
  DEFAULT_VALUES,
  PARAM_KEYS,
} from '@studio/features/projects/constants';
import { PROJECTS_FILTER_VIDEO_DURATION_CHANGE } from '@studio/features/projects/heap.constants';
import { useParamStore } from '@studio/stores';
import { Badge, Button, Icons, Label, Popover } from '@lib/ui';
import { STATSIG_FEATURE_FLAGS } from '@lib/utils';
import * as Styles from './filters.css';
import { SponsorFilterInput, TagsFilterInput } from './inputs';

export function OverflowFilters() {
  const { t } = useTranslation();
  const { params } = useParamStore();

  // if any other filters are added to this overflow components, add their params here
  const filterParams = {
    ...(params.duration__gte
      ? { [PARAM_KEYS.DURATION_GTE]: params.duration__gte }
      : {}),
    ...(params.duration__lte
      ? { [PARAM_KEYS.DURATION_LTE]: params.duration__lte }
      : {}),
    ...(params.sponsors__exists
      ? { [PARAM_KEYS.SPONSORS_EXISTS]: params.sponsors__exists }
      : {}),
    ...(params.videoProjectTags
      ? { [PARAM_KEYS.VIDEO_PROJECT_TAGS]: params.videoProjectTags }
      : {}),
  };

  // count duration with both values as only one filter
  if (filterParams.duration__gte && filterParams.duration__lte) {
    delete filterParams.duration__gte;
  }

  const projectsDefaultValues = DEFAULT_VALUES;

  const dirtyFilters = getDirtyFiltersCount(
    filterParams,
    projectsDefaultValues
  );

  const showTags = useGateValue(STATSIG_FEATURE_FLAGS.STUDIO_PROJECT_TAGS);

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <Button
          variant="subtle"
          size="sm"
          adornmentEnd={<Icons.ChevronDownIcon aria-hidden />}
        >
          {t('More')}
          {!!dirtyFilters && (
            <Badge variant="primary" pill className={Styles.countBadge}>
              {dirtyFilters}
            </Badge>
          )}
        </Button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content align="end" className={Styles.popoverContent}>
          <Popover.Close />
          <div className={Styles.moreFilters}>
            <div className={Styles.moreFilter}>
              <Label size="xs">{t('Video length')}</Label>
              <VideoDurationFilterInput
                minId={PARAM_KEYS.DURATION_GTE}
                maxId={PARAM_KEYS.DURATION_LTE}
                onChangeEventName={PROJECTS_FILTER_VIDEO_DURATION_CHANGE}
              />
            </div>
            <div className={Styles.moreFilter}>
              <Label size="xs">{t('Sponsor')}</Label>
              <SponsorFilterInput />
            </div>
            {showTags ? (
              <div className={Styles.moreFilter}>
                <Label size="xs">{t('Tags')}</Label>
                <TagsFilterInput />
              </div>
            ) : null}
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
