import { useTranslation } from 'react-i18next';
import { ScrollWrapper } from '@studio/components';
import { PARAM_KEYS } from '@studio/features/projects/constants';
import { useFetchChannelTags } from '@studio/features/projects/hooks';
import { useFetchAiCategories } from '@studio/features/projects/hooks/use-fetch-ai-categories';
import { AiCategory } from '@studio/features/projects/types';
import { useParamStore } from '@studio/stores';
import { Flex } from '@lib/ui';
import { AiCategoryChip } from './ai-category-chip';
import { TagChip } from './tag-chip';
import * as Styles from './tags-bar.css';

const NUM_TAGS = 5;

export function TagsBar() {
  const { t } = useTranslation();
  const { params, setParams, removeParam } = useParamStore();

  // TODO: optimize this as we're only showing the 5 most recent tags
  const { data, isLoading: isLoadingTags } = useFetchChannelTags();

  // prettier-ignore
  const { data: aiCategories, isLoading: isLoadingAiCats } = useFetchAiCategories();

  // prettier-ignore
  const aiCategoriesResults = aiCategories?.pages.flatMap((page) => page.results) ?? [];

  // populate selected tags and categories with any present in the URL params
  const selectedTags = params[PARAM_KEYS.VIDEO_PROJECT_TAGS]
    ? decodeURIComponent(params[PARAM_KEYS.VIDEO_PROJECT_TAGS]).split(',')
    : [];
  const selectedAiCategories = params[PARAM_KEYS.AI_CATEGORIES]
    ? decodeURIComponent(params[PARAM_KEYS.AI_CATEGORIES]).split(',')
    : [];

  // "All" chip is selected if no tags or categories are
  const isAllSelected =
    selectedTags.length === 0 && selectedAiCategories.length === 0;

  // just the 5 most recent tags
  let tagResults = data?.pages.flatMap((page) => page.results) ?? [];
  tagResults.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
  tagResults = tagResults.slice(0, NUM_TAGS);

  const onTagClick = (tag: string | null) => {
    removeParam(PARAM_KEYS.AI_CATEGORIES);

    if (!tag || selectedTags.includes(tag)) {
      // clicked All or toggled off the selected tag
      removeParam(PARAM_KEYS.VIDEO_PROJECT_TAGS);
      return;
    }

    setParams({
      [PARAM_KEYS.VIDEO_PROJECT_TAGS]: encodeURIComponent(tag),
    });
  };

  const onAiCategoryClick = (category: AiCategory) => {
    removeParam(PARAM_KEYS.VIDEO_PROJECT_TAGS);

    if (selectedAiCategories.includes(category.name)) {
      // toggled off the selected category
      removeParam(PARAM_KEYS.AI_CATEGORIES);
      return;
    }

    setParams({
      [PARAM_KEYS.AI_CATEGORIES]: encodeURIComponent(category.name),
    });
  };

  return (
    <Flex className={Styles.container}>
      <ScrollWrapper>
        <Flex className={Styles.tagsContainer}>
          <TagChip
            tag={t('All')}
            onClick={() => onTagClick(null)}
            selected={isAllSelected}
          />

          {isLoadingTags
            ? null // TODO: add a loading state? so far seems unnecessary
            : (tagResults ?? []).map((tag) => (
                <TagChip
                  key={tag.id}
                  tag={tag.content}
                  onClick={() => onTagClick(tag.content)}
                  selected={selectedTags.includes(tag.content)}
                />
              ))}

          {isLoadingAiCats
            ? null // TODO: add a loading state? so far seems unnecessary
            : aiCategoriesResults.map((category) => (
                <AiCategoryChip
                  key={category.name}
                  category={category}
                  onClick={onAiCategoryClick}
                  selected={selectedAiCategories.includes(category.name)}
                />
              ))}
        </Flex>
      </ScrollWrapper>
    </Flex>
  );
}
