import { forwardRef, type PointerEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import { useInspirationStore } from '@studio/features/ideation';
import {
  useIdeationStore,
  useNewProjectId,
} from '@studio/features/projects/stores';
import { ProjectQueryParams } from '@studio/features/projects/types';
import { useSavedItemsStore } from '@studio/features/saved-items/stores';
import { trackEvent } from '@studio/lib/heap';
import { useActiveOrganizationId, useModalStore } from '@studio/stores';
import {
  EXIT_IDEATION,
  OutlierVideo,
  SavedItem,
  SavedItemsType,
} from '@lib/types';
import { IconButton, Icons, Tooltip } from '@lib/ui';
import * as Styles from './brainstorm-action.css';

type BrainstormActionProps = {
  className?: string;
  element?: string;
  isHovered?: boolean;
  isInteractable?: boolean;
  isTopic?: boolean;
  showTooltip?: boolean;
  status?: string;
  topic?: string;
  trackingEvent?: string;
};

type BrainstormActionWithDataProps = BrainstormActionProps &
  (
    | {
        data?: OutlierVideo;
        type?: SavedItemsType.OUTLIERS;
      }
    | {
        data?: SavedItem;
        type?: Exclude<SavedItemsType, SavedItemsType.OUTLIERS>;
      }
  );

export const BrainstormAction = forwardRef<
  HTMLButtonElement,
  BrainstormActionWithDataProps
>(
  (
    {
      className,
      data,
      element = 'title',
      isHovered = false,
      isInteractable = true,
      isTopic = false,
      showTooltip = false,
      status,
      topic,
      trackingEvent,
      type,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const orgId = useActiveOrganizationId();
    const channelUcid = useActiveChannelUcid();
    const { toggleVisibility, visible } = useSavedItemsStore();
    const {
      reset,
      setConcepts,
      setOutliers,
      setThumbnails,
      setTitles,
      setTopic,
    } = useInspirationStore();
    const { inIdeation } = useIdeationStore();
    const { openModal } = useModalStore();
    const { getId } = useNewProjectId();

    const sendToBrainstorm = () => {
      reset();
      if (visible) {
        toggleVisibility();
      }
      if (!data && !isTopic) {
        return;
      }
      if (topic) {
        // TODO refactor to handle Topic setting more gracefully
        setTopic(topic);
      } else {
        switch (type) {
          case SavedItemsType.OUTLIERS:
            setOutliers([data] as OutlierVideo[], true);
            break;
          case SavedItemsType.CONCEPT:
            setConcepts([data] as SavedItem[], true);
            break;
          case SavedItemsType.THUMBNAIL:
            setThumbnails([data] as SavedItem[], true);
            break;
          case SavedItemsType.TITLE:
            setTitles([data] as SavedItem[], true);
            break;
          default:
            console.warn(`Unhandled type: ${type}`);
        }
      }

      if (trackingEvent) {
        trackEvent(trackingEvent, {
          keyword: topic ?? '',
          page: location.pathname,
          data: JSON.stringify(data),
        });
      }

      navigate({
        pathname: `/app/${orgId}/${channelUcid}/projects/new-project`,
        search: createSearchParams({
          [ProjectQueryParams.ELEMENT]: element,
          [ProjectQueryParams.BRAINSTORM]: 'true',
          [ProjectQueryParams.NEW_PROJECT_ID]: `${getId()}`,
          ...(status && { status }),
        }).toString(),
      });
    };

    const handleClick = (event: PointerEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (inIdeation) {
        openModal(EXIT_IDEATION, sendToBrainstorm);
        return;
      }
      sendToBrainstorm();
    };

    return (
      <Tooltip.Provider delayDuration={0}>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <IconButton
              icon={<Icons.AiGradientIcon aria-hidden />}
              radii="lg"
              size="md"
              variant="subtle"
              onClick={handleClick}
              className={Styles.overrides}
              {...rest}
              ref={ref}
            />
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content sideOffset={12}>{t('Brainstorm')}</Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    );
  }
);
