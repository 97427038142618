import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Radio } from '@lib/ui';

type Item = {
  id: string;
  label: string;
  value: string;
};

type AllowFilterProps = Radio.RadioProps & {
  id: string;
  value: string | undefined;
  setParams: (value: { [key: string]: string }) => void;
  items?: Item[];
};

export const AllowFilter = (props: AllowFilterProps) => {
  const { id, value, setParams, items } = props;
  const { t } = useTranslation();
  const radioValue = value === undefined ? '' : value;

  const defaultItems = useMemo(
    () => [
      {
        id: `${id}-yes`,
        label: t('Yes'),
        value: 'true',
      },
      {
        id: `${id}-no`,
        label: t('No'),
        value: 'false',
      },
      {
        id: `${id}-both`,
        label: t('Both'),
        value: '',
      },
    ],
    [id]
  );

  const handleChange = (value: string) => {
    setParams({ [id]: value });
  };

  return (
    <Flex flexDirection="column" gap="1rem">
      <Radio.Root
        id={id}
        layout="horizontal"
        value={radioValue}
        onValueChange={handleChange}
      >
        {(items ?? defaultItems).map((item) => (
          <Radio.Item fill="solid" key={`${id}-${item.id}`} {...item} />
        ))}
      </Radio.Root>
    </Flex>
  );
};
