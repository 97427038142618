import { useTranslation } from 'react-i18next';
import { useSpotterScoreStore } from '@studio/features/projects/stores';
import { Icons, Label, Switch } from '@lib/ui';
import * as Styles from './header.css';

export function ScoreToggle() {
  const { t } = useTranslation();
  const { visible, setVisibility } = useSpotterScoreStore();

  return (
    <div className={Styles.actionsItem}>
      <Label size="xs">
        <Icons.TrendingUpIcon aria-hidden className={Styles.labelIcon} />
        {t('Spotter Score')}
      </Label>
      <Switch.Root
        variant="subtle"
        checked={visible}
        onCheckedChange={setVisibility}
      >
        <Switch.Thumb />
      </Switch.Root>
    </div>
  );
}
