export function buildMultiplierRangeDisplayString(
  minValue?: string,
  maxValue?: string,
  minLabel?: string,
  maxLabel?: string
) {
  if (minValue && maxValue) {
    return `${minValue}x - ${maxValue}x`;
  }
  if (minValue) {
    return `${minLabel} ${minValue}x`;
  }
  if (maxValue) {
    return `${maxLabel} ${maxValue}x`;
  }
  return 'Any';
}
