import { InputChip } from '@lib/ui';
import * as Styles from './tag-chip.css';

export const TagChip = ({
  tag,
  onClick,
  selected,
}: {
  tag: string;
  onClick: () => void;
  selected?: boolean;
}) => (
  <InputChip
    pill
    condensed
    noTruncateLabel
    label={tag}
    onClick={onClick}
    fill={selected ? 'solid' : 'ghost'}
    className={Styles.tag}
  />
);
