import { KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchChannelTags } from '@studio/features/projects/hooks';
import { useOnClickOutside } from '@studio/hooks';
import { Tag } from '@lib/types';
import {
  Button,
  IconButton,
  Icons,
  Popover,
  TextInput,
  type TextInputVariants,
} from '@lib/ui';
import { TAGS_LABEL_MAX_LENGTH } from '../../constants';
import { LoadMoreTags } from './load-more-tags';
import * as Styles from './tags-select.css';

export type TagResultProps = Tag & {
  isSelected?: boolean;
  onAddTag: () => void;
};

export const TagResult = (props: TagResultProps) => {
  const { isSelected } = props;

  return (
    <Button
      className={Styles.result}
      variant="subtle"
      fill="ghost"
      size="sm"
      disabled={isSelected}
      onClick={props.onAddTag}
    >
      {props.content}
    </Button>
  );
};

interface TagsProps {
  onAddTag: (label: string) => void;
  selectedTags: Tag[];
  allowTagCreation?: boolean;
  triggerLabel?: string;
  // @ts-expect-error anyone know why it's claiming there's no size on TextInputVariants?
  inputSize?: TextInputVariants['size'];
}

// Disallow comma character "," on tag labels
const VALID_INPUT_PATTERN = /^[^,]*$/;

export const TagsSelect = (props: TagsProps) => {
  const {
    onAddTag,
    selectedTags,
    allowTagCreation,
    triggerLabel,
    inputSize = 'sm',
  } = props;
  const { t } = useTranslation();
  const [isAddingTag, setIsAddingTag] = useState(false);
  const [resultsMenuOpen, setResultsMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);

  const {
    data: channelTags,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useFetchChannelTags();

  const existingChannelTags =
    channelTags?.pages.flatMap((page) => page.results) || [];

  const filteredChannelTags = existingChannelTags.filter((tag) =>
    tag.content.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const focusTextInput = () => {
    // Ensures focus after popover trigger
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const handleOpenChange = (open: boolean) => {
    setResultsMenuOpen((prev) => !prev);
    if (open && inputRef.current) {
      focusTextInput();
    }
  };

  useOnClickOutside(containerRef, () => {
    setResultsMenuOpen(false);
    setIsAddingTag(false);
  });

  const clearSearch = () => setSearchTerm('');

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (!searchTerm.trim()) {
        return;
      }

      const tagExistInChannel = existingChannelTags.some(
        (tag) =>
          tag.content.trim().toLowerCase() === searchTerm.trim().toLowerCase()
      );

      if (tagExistInChannel || allowTagCreation) {
        onAddTag(searchTerm);
        setResultsMenuOpen(false);
        clearSearch();
      }
    }
  };

  useEffect(() => {
    if (searchTerm.length > 0) {
      setResultsMenuOpen(true);
      focusTextInput();
    } else {
      setResultsMenuOpen(false);
    }
  }, [searchTerm]);

  const handleAddTag = (content: string) => {
    setResultsMenuOpen(false);
    clearSearch();
    onAddTag(content);
  };

  const handleAddClick = () => {
    setIsAddingTag(true);
    focusTextInput();
    setResultsMenuOpen(true);
  };

  const handleInputChange = (value: string) => {
    if (!VALID_INPUT_PATTERN.test(value)) {
      return;
    }
    setSearchTerm(value);
  };

  return (
    <div className={Styles.tagsContainer}>
      {!isAddingTag ? (
        <Button
          fill="ghost"
          variant="subtle"
          size="xs"
          className={Styles.addButton}
          onClick={handleAddClick}
          title={triggerLabel ? t(triggerLabel) : t('Add tag')}
        >
          <Icons.AddIcon aria-hidden />
          {triggerLabel && t(triggerLabel)}
        </Button>
      ) : (
        <Popover.Root open={resultsMenuOpen} onOpenChange={handleOpenChange}>
          <Popover.Trigger asChild className={Styles.trigger}>
            <TextInput.Root
              variant="dark"
              fill="sheer"
              size={inputSize}
              radii={inputSize}
            >
              <TextInput.Input
                placeholder={t('Enter tag')}
                value={searchTerm}
                onKeyDown={handleKeyDown}
                ref={inputRef}
                maxLength={TAGS_LABEL_MAX_LENGTH}
                onChange={handleInputChange}
              >
                {searchTerm ? (
                  <TextInput.Adornment align="end">
                    <IconButton
                      fill="none"
                      icon={<Icons.CloseIcon aria-hidden />}
                      iconSize="14"
                      label={t('Clear')}
                      size="xs"
                      variant="subtle"
                      onClick={clearSearch}
                    />
                  </TextInput.Adornment>
                ) : null}
              </TextInput.Input>
            </TextInput.Root>
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content
              align="center"
              sideOffset={8}
              className={Styles.resultsContainer}
              ref={containerRef}
            >
              <Popover.Close />
              {filteredChannelTags.length > 0 ? (
                <div ref={panelRef} className={Styles.results}>
                  {filteredChannelTags.map((existingTag) => {
                    const isSelected = !!selectedTags?.find(
                      (tag) => tag.id === existingTag.id
                    );
                    return (
                      <TagResult
                        key={existingTag.id}
                        isSelected={isSelected}
                        {...existingTag}
                        onAddTag={() => handleAddTag(existingTag.content)}
                      />
                    );
                  })}
                  {hasNextPage ? (
                    <LoadMoreTags
                      hasNextPage={hasNextPage}
                      fetchNextPage={fetchNextPage}
                      isFetchingNextPage={isFetchingNextPage}
                    />
                  ) : null}
                </div>
              ) : null}
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      )}
    </div>
  );
};
