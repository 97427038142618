import { ForwardedRef, forwardRef, PropsWithChildren } from 'react';
import clsx from 'clsx';
import { Flex } from '@lib/ui';
import * as Styles from './page-layout.css';

type PageLayoutProps = PropsWithChildren & {
  className?: string;
};

export const Root = ({ children, className }: PageLayoutProps) => {
  return <Flex className={clsx(Styles.root, className)}>{children}</Flex>;
};

export const Container = ({ children, className }: PageLayoutProps) => {
  return (
    <Flex as="main" className={clsx(Styles.container, className)}>
      {children}
    </Flex>
  );
};

export const Content = forwardRef(
  (
    { children, className }: PageLayoutProps,
    ref: ForwardedRef<HTMLElement>
  ) => {
    return (
      <Flex ref={ref} className={clsx(Styles.content, className)}>
        {children}
      </Flex>
    );
  }
);

export const Column = forwardRef(
  (
    { children, className }: PageLayoutProps,
    ref: ForwardedRef<HTMLElement>
  ) => {
    return (
      <Flex ref={ref} className={clsx(Styles.column, className)}>
        {children}
      </Flex>
    );
  }
);

export const DetailRow = ({ children, className }: PageLayoutProps) => {
  return <div className={clsx(Styles.grail, className)}>{children}</div>;
};

export const Title = ({ children, className }: PageLayoutProps) => {
  return <div className={clsx(Styles.title, className)}>{children}</div>;
};
