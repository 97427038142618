import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGateValue } from '@statsig/react-bindings';
import {
  OutliersFilterInput,
  VideoDurationFilterInput,
  VideoViewsFilterInput,
} from '@studio/components/filters/inputs';
import { getDirtyFiltersCount } from '@studio/components/filters/utils';
import {
  DEFAULT_VALUES,
  DEFAULT_VALUES_IDEA_BANK,
  PARAM_KEYS,
} from '@studio/features/projects/constants';
import {
  PROJECTS_FILTER_OUTLIER_CHANGE,
  PROJECTS_FILTER_OUTLIER_OPEN,
  PROJECTS_FILTER_VIDEO_DURATION_CHANGE,
  PROJECTS_FILTER_VIDEO_VIEWS_CHANGE,
} from '@studio/features/projects/heap.constants';
import { useParamStore } from '@studio/stores';
import { PROJECT_STATUS } from '@lib/types';
import { Badge, Button, Icons, Label, Popover } from '@lib/ui';
import { STATSIG_FEATURE_FLAGS } from '@lib/utils';
import * as Styles from './filters.css';
import {
  AccessFilterInput,
  PublishDateFilterInput,
  SponsorFilterInput,
  TagsFilterInput,
} from './inputs';

export function Filters() {
  const { t } = useTranslation();
  const { params, setParams } = useParamStore();
  const ideaBankEnabled = useGateValue(STATSIG_FEATURE_FLAGS.STUDIO_IDEA_BANK);

  const currentStatus = params[PARAM_KEYS.STATUS_IN];

  const isIdeaRoute = params[PARAM_KEYS.STATUS_IN] === PROJECT_STATUS.IDEA;
  const isDevRoute = ideaBankEnabled
    ? params[PARAM_KEYS.STATUS_IN] === PROJECT_STATUS.IN_DEVELOPMENT
    : params[PARAM_KEYS.PUBLISHED] === 'false';
  const isPublishedRoute = ideaBankEnabled
    ? params[PARAM_KEYS.STATUS_IN] === PROJECT_STATUS.PUBLISHED
    : params[PARAM_KEYS.PUBLISHED] === 'true';

  // if any other filters are added to this component, add their params here
  const filterParams = {
    ...(params.access ? { [PARAM_KEYS.ACCESS]: params.access } : {}),
    ...(params.outlierIndex__gte
      ? { [PARAM_KEYS.OUTLIER_INDEX_GTE]: params.outlierIndex__gte }
      : {}),
    ...(params.outlierIndex__lte
      ? { [PARAM_KEYS.OUTLIER_INDEX_LTE]: params.outlierIndex__lte }
      : {}),
    ...(params.views__gte ? { [PARAM_KEYS.VIEWS_GTE]: params.views__gte } : {}),
    ...(params.views__lte ? { [PARAM_KEYS.VIEWS_LTE]: params.views__lte } : {}),
    ...(params.publishDate__gte
      ? { [PARAM_KEYS.PUBLISH_DATE_START]: params.publishDate__gte }
      : {}),
    ...(params.publishDate__lte
      ? { [PARAM_KEYS.PUBLISH_DATE_END]: params.publishDate__lte }
      : {}),
    ...(params.duration__gte
      ? { [PARAM_KEYS.DURATION_GTE]: params.duration__gte }
      : {}),
    ...(params.duration__lte
      ? { [PARAM_KEYS.DURATION_LTE]: params.duration__lte }
      : {}),
    ...(params.sponsors__exists
      ? { [PARAM_KEYS.SPONSORS_EXISTS]: params.sponsors__exists }
      : {}),
    ...(params.videoProjectTags
      ? { [PARAM_KEYS.VIDEO_PROJECT_TAGS]: params.videoProjectTags }
      : {}),
  };

  // count duration with both values as only one filter
  if (filterParams.duration__gte && filterParams.duration__lte) {
    delete filterParams.duration__gte;
  }

  const projectsDefaultValues = ideaBankEnabled
    ? DEFAULT_VALUES_IDEA_BANK
    : DEFAULT_VALUES;

  const handleResetFilters = () => {
    setParams(() => ({
      ...projectsDefaultValues,
      [PARAM_KEYS.STATUS_IN]: currentStatus,
    }));
  };

  const dirtyFilters = getDirtyFiltersCount(
    filterParams,
    projectsDefaultValues
  );

  const alphaFiltersEnabled = useGateValue(
    STATSIG_FEATURE_FLAGS.STUDIO_PROJECTS_FILTERS_ALPHA
  );

  const showTags = useGateValue(STATSIG_FEATURE_FLAGS.STUDIO_PROJECT_TAGS);

  useEffect(() => {
    handleResetFilters();
  }, [isIdeaRoute, isDevRoute, isPublishedRoute]);

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <Button
          variant="subtle"
          fill="sheer"
          size="sm"
          adornmentEnd={<Icons.FilterIcon aria-hidden />}
        >
          {t('Filters')}
          {!!dirtyFilters && (
            <Badge variant="primary" pill className={Styles.countBadge}>
              {dirtyFilters}
            </Badge>
          )}
        </Button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content align="end" className={Styles.popoverContent}>
          <Popover.Close />
          <div className={Styles.filterHeader}>
            <Label size="sm" variant="subtle">
              {t('Filters')}
            </Label>
            <Button
              variant="subtle"
              size="xs"
              fill="none"
              disabled={!dirtyFilters}
              adornmentEnd={<Icons.UndoIcon aria-hidden />}
              onClick={handleResetFilters}
            >
              {t('Reset')}
            </Button>
          </div>
          <div className={Styles.moreFilters}>
            {isPublishedRoute && alphaFiltersEnabled ? (
              <>
                <div className={Styles.moreFilter}>
                  <Label size="xs">{t('Outliers')}</Label>
                  <OutliersFilterInput
                    minId={PARAM_KEYS.OUTLIER_INDEX_GTE}
                    maxId={PARAM_KEYS.OUTLIER_INDEX_LTE}
                    eventNames={{
                      opened: PROJECTS_FILTER_OUTLIER_OPEN,
                      changed: PROJECTS_FILTER_OUTLIER_CHANGE,
                    }}
                  />
                </div>
                <div className={Styles.moreFilter}>
                  <Label size="xs">{t('Views')}</Label>
                  <VideoViewsFilterInput
                    minId={PARAM_KEYS.VIEWS_GTE}
                    maxId={PARAM_KEYS.VIEWS_LTE}
                    onChangeEventName={PROJECTS_FILTER_VIDEO_VIEWS_CHANGE}
                  />
                </div>
              </>
            ) : null}
            {isDevRoute ? (
              <div className={Styles.moreFilter}>
                <Label size="xs">{t('Access')}</Label>
                <AccessFilterInput />
              </div>
            ) : null}
            {!isIdeaRoute ? (
              <>
                <div className={Styles.moreFilter}>
                  <Label size="xs">{t('Publish date')}</Label>
                  <PublishDateFilterInput
                    minId={PARAM_KEYS.PUBLISH_DATE_START}
                    maxId={PARAM_KEYS.PUBLISH_DATE_END}
                  />
                </div>
                <div className={Styles.moreFilter}>
                  <Label size="xs">{t('Video length')}</Label>
                  <VideoDurationFilterInput
                    minId={PARAM_KEYS.DURATION_GTE}
                    maxId={PARAM_KEYS.DURATION_LTE}
                    onChangeEventName={PROJECTS_FILTER_VIDEO_DURATION_CHANGE}
                  />
                </div>
                <div className={Styles.moreFilter}>
                  <Label size="xs">{t('Sponsor')}</Label>
                  <SponsorFilterInput />
                </div>
              </>
            ) : null}
            {showTags ? (
              <div className={Styles.moreFilter}>
                <Label size="xs">{t('Tags')}</Label>
                <TagsFilterInput />
              </div>
            ) : null}
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
