import { Tag } from '@lib/types';
import { InputChip } from '@lib/ui';
import * as Styles from './tags-list.css';

type Props = {
  selectedTags: Tag[];
  onRemoveTag: (tag: Tag) => void;
};

export const TagsList = ({ selectedTags, onRemoveTag }: Props) => {
  return (
    <>
      {selectedTags?.length > 0 && (
        <div className={Styles.tagsList}>
          {selectedTags.map((tag) => (
            <InputChip
              key={tag.id}
              label={tag.content}
              fill="ghost"
              condensed
              onDismiss={() => onRemoveTag(tag)}
            />
          ))}
        </div>
      )}
    </>
  );
};
