import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { VideoViewsFilterInput } from '@studio/components/filters/inputs';
import { useParamStore } from '@studio/stores';
import { Button, Icons, Popover } from '@lib/ui';
import { abbreviateNumberRangeDisplayString } from '@lib/utils';
import { PARAM_KEYS } from '../../../constants';
import { PROJECTS_FILTER_VIDEO_VIEWS_CHANGE } from '../../../heap.constants';
import * as Styles from '../header.css';

export function VideoViewsFilterOld() {
  const { t } = useTranslation();
  const {
    params: { views__gte, views__lte },
  } = useParamStore();

  const hasRange = views__gte?.length > 0 || views__lte?.length > 0;
  const range =
    abbreviateNumberRangeDisplayString(
      views__gte,
      views__lte,
      t('Min'),
      t('Max')
    ) || t('Any');

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <Button
          variant="subtle"
          size="sm"
          adornmentEnd={<Icons.ChevronDownIcon aria-hidden />}
        >
          {t('Views')}:{' '}
          <span className={clsx(hasRange && Styles.primaryText)}>{range}</span>
        </Button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          align="start"
          className={Styles.popoverContent}
          onOpenAutoFocus={(event) => event.preventDefault()}
        >
          <VideoViewsFilterInput
            minId={PARAM_KEYS.VIEWS_GTE}
            maxId={PARAM_KEYS.VIEWS_LTE}
            onChangeEventName={PROJECTS_FILTER_VIDEO_VIEWS_CHANGE}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
