export const STATSIG_FEATURE_FLAGS = {
  POWER_KEYWORD_TREND_SPOTTER_ACCESS: 'power_keywords_trendspotter_access',
  POWER_KEYWORD_TITLE_ROULETTE_ACCESS: 'power_keywords_titleroulette_access',
  PROJECT_DETAIL_BREADCRUMBS: 'studio_project_show_breadcrumbs',
  PROJECT_DETAIL_PANEL_LAYOUT: 'studio_project_panel_layout_swap',
  STUDIO_CASTING_MEMBER_LOGIC_CHANGE: 'studio_casting_member_logic_change',
  STUDIO_BRAINSTORM_POWER_KEYWORDS_EDIT:
    'studio_brainstorm_power_keywords_edit',
  STUDIO_BRAINSTORM_THUMBNAIL_CAMERA_ANGLES:
    'studio_brainstorm_thumbnail_camera_angles',
  STUDIO_SETTINGS_SHOW_REFERRAL: 'studio_settings_show_referral',
  STUDIO_REFERRAL_HOLIDAY_TREATMENT: 'studio_referral_holiday_treatment',
  STUDIO_SHOW_DEFAULT_CHANNEL_LIST_IN_ONBOARDING_FOLLOW_CHANNELS:
    'studio_show_default_channel_list_in_onboarding_follow_channels',
  STUDIO_SHOW_BRAINSTORM_POWER_KEYWORDS:
    'studio_show_brainstorm_power_keywords',
  STUDIO_ONBOARDING_ALLOW_HEIC_UPLOAD: 'studio_onboarding_allow_heic_upload',
  STUDIO_PROJECT_PANEL_YOUTUBE_PREVIEW: 'studio_project_panel_youtube_preview',
  STUDIO_PROJECTS_FILTERS_ALPHA: 'studio_projects_filters',
  STUDIO_PROJECT_TAGS: 'studio_project_tags',
  STUDIO_IDEA_BANK: 'studio_idea_bank',
  STUDIO_IDEAS_FOR_YOU: 'studio_ideas_for_you',
  STUDIO_OUTLIERS_REPLACE_FILTERS: 'studio_outliers_replace_filters',
  STUDIO_ALLOW_GENERATED_BIO_OVERRIDE: 'studio_allow_generatedbio_override',
  STUDIO_OUTLIERS_DEFAULT_PRESET: 'studio_outliers_default_preset',
  STUDIO_FREEFORM_IDEATION: 'studio_freeform_ideation',
} as const;

export const STATSIG_AB_TESTS = {
  BRAINSTORM_MODE_NAV_PLACEMENT: {
    id: 'studio_brainstorm_mode_navigation_position',
    param: 'navigation_placement',
  },
  PRICING_PLAN_AB_TEST_ID: 'pricing_plan_ab_test_-_yearly_plan_default_db-4244',
  SUSI_BUTTERMILK_BACKGROUND_TEST: 'studio_susi_buttermilk_background_ab_test',
} as const;
