import { useState } from 'react';
import { t } from 'i18next';
import { PARAM_KEYS, SORT_ORDER } from '@studio/features/projects';
import * as EVENTS from '@studio/features/projects/heap.constants';
import { useParamStore } from '@studio/stores';
import { PROJECT_STATUS } from '@lib/types';
import { Icons, Select, SuggestionChip } from '@lib/ui';
import * as Styles from '../header.css';

type SortOptionItem = { children: string; value: string; eventLabel?: string };
type SortOptionGroup = { groupLabel: string; items: SortOptionItem[] };

export function SortSelect() {
  const { params, setParamsTracked } = useParamStore();
  const [isOpen, setIsOpen] = useState(false);

  const isIdeaRoute = params[PARAM_KEYS.STATUS_IN] === PROJECT_STATUS.IDEA;

  const sortData = {
    [SORT_ORDER.CREATED_ASC]:
      EVENTS.PROJECTS_SORT_CREATED_DATE_NEWEST_TO_OLDEST_CLICK,
    [SORT_ORDER.CREATED_DESC]:
      EVENTS.PROJECTS_SORT_CREATED_DATE_OLDEST_TO_NEWEST_CLICK,
    [SORT_ORDER.PUBLISHED_ASC]:
      EVENTS.PROJECTS_SORT_PUBLISH_DATE_NEWEST_TO_OLDEST_CLICK,
    [SORT_ORDER.PUBLISHED_DESC]:
      EVENTS.PROJECTS_SORT_PUBLISH_DATE_OLDEST_TO_NEWEST_CLICK,
    [SORT_ORDER.RANK_ASC]:
      EVENTS.PROJECTS_SORT_USER_RANK_HIGHEST_TO_LOWEST_CLICK,
    [SORT_ORDER.RANK_DESC]:
      EVENTS.PROJECTS_SORT_USER_RANK_LOWEST_TO_HIGHEST_CLICK,
    [SORT_ORDER.SPOTTERS_ASC]:
      EVENTS.PROJECTS_SORT_SPOTTER_SCORE_HIGHEST_TO_LOWEST_CLICK,
    [SORT_ORDER.SPOTTERS_DESC]:
      EVENTS.PROJECTS_SORT_SPOTTER_SCORE_LOWEST_TO_HIGHEST_CLICK,
    [SORT_ORDER.NAME_ASC]: EVENTS.PROJECTS_SORT_TITLE_A_Z_CLICK,
    [SORT_ORDER.NAME_DESC]: EVENTS.PROJECTS_SORT_TITLE_Z_A_CLICK,
    [SORT_ORDER.EDITED_ASC]:
      EVENTS.PROJECTS_SORT_LAST_EDITED_NEWEST_TO_OLDEST_CLICK,
    [SORT_ORDER.EDITED_DESC]:
      EVENTS.PROJECTS_SORT_LAST_EDITED_OLDEST_TO_NEWEST_CLICK,
  } as const;

  let sortOptions: SortOptionGroup[] = [];

  if (isIdeaRoute) {
    sortOptions = [
      {
        groupLabel: t('Created date'),
        items: [
          {
            children: t('New to old'),
            value: SORT_ORDER.CREATED_ASC,
            eventLabel:
              EVENTS.PROJECTS_SORT_CREATED_DATE_NEWEST_TO_OLDEST_CLICK,
          },
          {
            children: t('Old to new'),
            value: SORT_ORDER.CREATED_DESC,
            eventLabel:
              EVENTS.PROJECTS_SORT_CREATED_DATE_OLDEST_TO_NEWEST_CLICK,
          },
        ],
      },
      {
        groupLabel: t('Last edited'),
        items: [
          {
            children: t('New to old'),
            value: SORT_ORDER.EDITED_ASC,
            eventLabel: EVENTS.PROJECTS_SORT_LAST_EDITED_NEWEST_TO_OLDEST_CLICK,
          },
          {
            children: t('Old to new'),
            value: SORT_ORDER.EDITED_DESC,
            eventLabel: EVENTS.PROJECTS_SORT_LAST_EDITED_OLDEST_TO_NEWEST_CLICK,
          },
        ],
      },
      {
        groupLabel: t('My score'),
        items: [
          {
            children: t('High to low'),
            value: SORT_ORDER.RANK_ASC,
            eventLabel: EVENTS.PROJECTS_SORT_USER_RANK_HIGHEST_TO_LOWEST_CLICK,
          },
          {
            children: t('Low to high'),
            value: SORT_ORDER.RANK_DESC,
            eventLabel: EVENTS.PROJECTS_SORT_USER_RANK_LOWEST_TO_HIGHEST_CLICK,
          },
        ],
      },
      {
        groupLabel: t('Spotter score'),
        items: [
          {
            children: t('High to low'),
            value: SORT_ORDER.SPOTTERS_ASC,
            eventLabel:
              EVENTS.PROJECTS_SORT_SPOTTER_SCORE_HIGHEST_TO_LOWEST_CLICK,
          },
          {
            children: t('Low to high'),
            value: SORT_ORDER.SPOTTERS_DESC,
            eventLabel:
              EVENTS.PROJECTS_SORT_SPOTTER_SCORE_LOWEST_TO_HIGHEST_CLICK,
          },
        ],
      },
    ];
  } else {
    sortOptions = [
      {
        groupLabel: t('Last edited'),
        items: [
          {
            children: t('New to old'),
            value: SORT_ORDER.EDITED_ASC,
            eventLabel: EVENTS.PROJECTS_SORT_LAST_EDITED_NEWEST_TO_OLDEST_CLICK,
          },
          {
            children: t('Old to new'),
            value: SORT_ORDER.EDITED_DESC,
            eventLabel: EVENTS.PROJECTS_SORT_LAST_EDITED_OLDEST_TO_NEWEST_CLICK,
          },
        ],
      },
      {
        groupLabel: t('Publish date'),
        items: [
          {
            children: t('New to old'),
            value: SORT_ORDER.PUBLISHED_ASC,
            eventLabel:
              EVENTS.PROJECTS_SORT_PUBLISH_DATE_NEWEST_TO_OLDEST_CLICK,
          },
          {
            children: t('Old to new'),
            value: SORT_ORDER.PUBLISHED_DESC,
            eventLabel:
              EVENTS.PROJECTS_SORT_PUBLISH_DATE_OLDEST_TO_NEWEST_CLICK,
          },
        ],
      },
      {
        groupLabel: t('Created date'),
        items: [
          {
            children: t('New to old'),
            value: SORT_ORDER.CREATED_ASC,
            eventLabel:
              EVENTS.PROJECTS_SORT_CREATED_DATE_NEWEST_TO_OLDEST_CLICK,
          },
          {
            children: t('Old to new'),
            value: SORT_ORDER.CREATED_DESC,
            eventLabel:
              EVENTS.PROJECTS_SORT_CREATED_DATE_OLDEST_TO_NEWEST_CLICK,
          },
        ],
      },
      {
        groupLabel: t('Project name'),
        items: [
          {
            children: t('A - Z'),
            value: SORT_ORDER.NAME_ASC,
            eventLabel: EVENTS.PROJECTS_SORT_TITLE_A_Z_CLICK,
          },
          {
            children: t('Z - A'),
            value: SORT_ORDER.NAME_DESC,
            eventLabel: EVENTS.PROJECTS_SORT_TITLE_Z_A_CLICK,
          },
        ],
      },
    ];
  }

  const handleSort = (value: keyof typeof sortData) => {
    setParamsTracked(sortData?.[value], { [PARAM_KEYS.SORT_BY]: value });
  };

  const appliedLabel =
    sortOptions
      .flatMap((group) => group.items)
      .find((item) => item.value === params[PARAM_KEYS.SORT_BY])?.children ||
    '';

  const SelectGroup = ({
    groupLabel,
    items,
  }: {
    groupLabel: string;
    items: SortOptionItem[];
  }) => (
    <Select.Group>
      <Select.Label>{groupLabel}</Select.Label>
      {items.map((item) => (
        <SelectItem key={`group-item-${item.value}`} {...item} />
      ))}
    </Select.Group>
  );

  const SelectItem = ({ children, value }: SortOptionItem) => (
    <Select.Item key={`item-${value}`} value={value}>
      <Select.ItemText>{children}</Select.ItemText>
      <Select.ItemIndicator>
        <Icons.CheckmarkIcon aria-hidden />
      </Select.ItemIndicator>
    </Select.Item>
  );

  const sortBy = params[PARAM_KEYS.SORT_BY] || SORT_ORDER.CREATED_ASC;

  return (
    <Select.Root
      value={sortBy}
      onValueChange={handleSort}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <Select.Trigger unstyled asChild>
        <SuggestionChip
          isRequired
          radius="md"
          label={t('Sort')}
          applied={Boolean(appliedLabel)}
          appliedLabel={t(appliedLabel)}
          onClick={() => {
            window.dispatchEvent(new CustomEvent('closeAllPopovers'));
          }}
        />
      </Select.Trigger>
      <Select.Portal>
        <Select.Content align="end" className={Styles.sortChipContent}>
          <Select.Viewport key="viewport-sort-select">
            {sortOptions.map((group, index) => (
              <>
                {index !== 0 ? (
                  <Select.Separator key={`separator-${group.groupLabel}`} />
                ) : null}
                <SelectGroup key={`label-${group.groupLabel}`} {...group} />
              </>
            ))}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
}
