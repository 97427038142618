import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLocalStorage } from '@studio/utils';
import { Flex, Heading } from '@lib/ui';
import {
  NewAuthLayout,
  ChooseYourPlan,
  AccentSection,
  AboutSpotter,
  NewAuthFooter,
  FixedCTA,
  HandleErrorNavigationBoundary,
} from '../../components';
import {
  PARTNER_STACK_KEY,
  PartnerStackUTMParams,
  useFetchStripePlanById,
} from '../../hooks';
import * as SharedStylesWithWhite from '../../routes/routes/new-shared-including-white-theme.css';
import { PlanType } from '../../types';
import * as Styles from './pricing-plans.css';

const defaultStripePlanId = import.meta.env.VITE_STRIPE_STUDIO_PLAN_ID;

export function PricingPlans() {
  const { t } = useTranslation();
  const { getItem } = getLocalStorage();
  const utmParams = getItem<PartnerStackUTMParams>(PARTNER_STACK_KEY);
  const planId = utmParams?.plan;
  const stripeProductId = !planId ? defaultStripePlanId : planId;
  const { data: plan, isLoading } = useFetchStripePlanById(stripeProductId);
  const [selectedPlan, setSelectedPlan] = useState<PlanType | undefined>(
    PlanType.Monthly
  );

  if (isLoading && !plan) {
    return (
      <NewAuthLayout>
        <HandleErrorNavigationBoundary>
          <Flex className={SharedStylesWithWhite.exceptionScreen}>
            <Flex className={SharedStylesWithWhite.container}>
              <Flex className={SharedStylesWithWhite.focusContainer}>
                <Heading className={SharedStylesWithWhite.title}>
                  {t('Loading...')}
                </Heading>
              </Flex>
            </Flex>
            <NewAuthFooter />
          </Flex>
        </HandleErrorNavigationBoundary>
      </NewAuthLayout>
    );
  }

  if (!plan) {
    return (
      <NewAuthLayout>
        <Flex className={SharedStylesWithWhite.exceptionScreen}>
          <Flex className={SharedStylesWithWhite.container}>
            <Flex className={SharedStylesWithWhite.focusContainer}>
              <Heading className={SharedStylesWithWhite.title}>
                {t(`No plans available.`)}
              </Heading>
            </Flex>
          </Flex>
          <NewAuthFooter />
        </Flex>
      </NewAuthLayout>
    );
  }

  return (
    <NewAuthLayout>
      <div className={Styles.ColorSchemeWrapper}>
        <Flex className={Styles.mainContainer}>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className={Styles.focusSection}
          >
            <ChooseYourPlan
              plan={plan}
              setSelectedPlan={setSelectedPlan}
              selectedPlan={selectedPlan}
              className={Styles.focusSection}
            />
          </Flex>
          <Flex className={Styles.accentSection}>
            <AccentSection />
          </Flex>
        </Flex>
        <AboutSpotter />
        <div className={Styles.footerWrapper}>
          <NewAuthFooter />
        </div>
        <FixedCTA selectedPlan={selectedPlan} plan={plan} />
      </div>
    </NewAuthLayout>
  );
}
