import {
  ForwardedRef,
  forwardRef,
  type ButtonHTMLAttributes,
  type ReactNode,
  type ReactElement,
} from 'react';
import clsx from 'clsx';
import * as Styles from './ai-button.css';

export type AiButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  Styles.AiButtonVariants & {
    adornmentEnd?: ReactElement;
    adornmentStart?: ReactElement;
    children?: ReactNode;
    loading?: boolean;
    type?: 'button' | 'submit' | 'reset';
  };

export const AiButton = forwardRef(
  (props: AiButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
      adornmentEnd,
      adornmentStart,
      children,
      type = 'button',
      size = 'md',
      variant = 'dark',
      radii = 'pill',
      className,
      ...rest
    } = props;

    return (
      <button
        ref={ref}
        type={type}
        className={clsx(
          Styles.aiButtonVariants({ size, radii, variant }),
          props.loading && 'loading',
          className
        )}
        {...rest}
      >
        {' '}
        {adornmentStart ? (
          <span className={Styles.adornment}>{adornmentStart}</span>
        ) : null}
        {children}
        {adornmentEnd ? (
          <span className={Styles.adornment}>{adornmentEnd}</span>
        ) : null}
      </button>
    );
  }
);
