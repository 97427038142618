import { trackEvent } from '@studio/lib/heap';
import { PUBLISHED } from '../../constants';
import * as EVENTS from '../../heap.constants';

export function useProjectsHeaderAnalytics() {
  const onProjectsPublishedToggle = (
    value: typeof PUBLISHED.TRUE | typeof PUBLISHED.FALSE
  ) => {
    const oldValue = value === PUBLISHED.TRUE ? 'In Progress' : 'Published';
    const newValue = value === PUBLISHED.FALSE ? 'In Progress' : 'Published';

    trackEvent(EVENTS.PROJECTS_PUBLISHED_STATUS_TOGGLE, {
      old_value: oldValue,
      new_value: newValue,
    });
  };

  return { onProjectsPublishedToggle };
}
