import { useState } from 'react';
import { AllowFilter } from '@studio/components';
import { PARAM_KEYS, SPONSORED } from '@studio/features/projects/constants';
import * as EVENTS from '@studio/features/projects/heap.constants';
import { trackEvent } from '@studio/lib/heap';
import { useParamStore } from '@studio/stores';

export const sponsorFilterItems = [
  {
    id: `${PARAM_KEYS.SPONSORS_EXISTS}-${SPONSORED.ALL}`,
    label: 'All',
    value: SPONSORED.ALL,
  },
  {
    id: `${PARAM_KEYS.SPONSORS_EXISTS}-${SPONSORED.YES}`,
    label: 'Yes',
    value: SPONSORED.YES,
  },
  {
    id: `${PARAM_KEYS.SPONSORS_EXISTS}-${SPONSORED.NO}`,
    label: 'No',
    value: SPONSORED.NO,
  },
];

export function SponsorFilterInput() {
  const { params, removeParam, setParams } = useParamStore();
  const [selectedValue, setSelectedValue] = useState<string>(
    params[PARAM_KEYS.SPONSORS_EXISTS] || sponsorFilterItems[0].value
  );

  const handleChange = (param: { [key: string]: string }) => {
    trackEvent(EVENTS.PROJECTS_FILTER_SPONSORS_CHANGE, {
      old_value: selectedValue,
      new_value: param?.sponsors__exists,
    });
    setSelectedValue(param.sponsors__exists);
    if (param.sponsors__exists === SPONSORED.ALL) {
      removeParam(PARAM_KEYS.SPONSORS_EXISTS);
    } else {
      setParams(param);
    }
  };

  return (
    <AllowFilter
      id={PARAM_KEYS.SPONSORS_EXISTS}
      value={selectedValue}
      setParams={handleChange}
      items={sponsorFilterItems}
    />
  );
}
