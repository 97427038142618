import { useGateValue } from '@statsig/react-bindings';
import clsx from 'clsx';
import { TagsBar } from '@studio/features/projects/components';
import { useParamStore } from '@studio/stores';
import { PROJECT_STATUS, PROJECT_STATUS_ITEMS } from '@lib/types';
import { PageHeader } from '@lib/ui';
import { STATSIG_FEATURE_FLAGS } from '@lib/utils';
import { PARAM_KEYS } from '../../constants';
import * as Styles from './header.css';
import {
  Filters,
  OutlierFilterOld,
  OverflowFilters,
  ScoreToggle,
  SortSelect,
  SortSelectOld,
  StatusToggle,
  VideoViewsFilterOld,
  StatusFilter,
  ScopeFilter,
  PublishDateFilterOld,
} from './';

// Ideas and Published are presented in their own tabs
const statusItemsSansPublished = PROJECT_STATUS_ITEMS.filter(
  (item) => item.value !== PROJECT_STATUS.PUBLISHED
);

export function Header() {
  const { params } = useParamStore();
  const ideaBankEnabled = useGateValue(STATSIG_FEATURE_FLAGS.STUDIO_IDEA_BANK);
  const alphaFiltersEnabled = useGateValue(
    STATSIG_FEATURE_FLAGS.STUDIO_PROJECTS_FILTERS_ALPHA
  );
  const isPublishedRoute = ideaBankEnabled
    ? params[PARAM_KEYS.STATUS_IN] === PROJECT_STATUS.PUBLISHED
    : params[PARAM_KEYS.PUBLISHED] === 'true';
  const isDevRoute = ideaBankEnabled
    ? params[PARAM_KEYS.STATUS_IN] === PROJECT_STATUS.IN_DEVELOPMENT
    : params[PARAM_KEYS.PUBLISHED] === 'false';
  const isIdeaRoute = params[PARAM_KEYS.STATUS_IN] === PROJECT_STATUS.IDEA;

  const oldDevFilters = ideaBankEnabled ? null : (
    <>
      <StatusFilter items={statusItemsSansPublished} />
      <ScopeFilter />
    </>
  );

  const publishedFilters = ideaBankEnabled ? null : (
    <>
      <ScopeFilter />
      {alphaFiltersEnabled ? (
        <>
          <OutlierFilterOld />
          <VideoViewsFilterOld />
        </>
      ) : null}
    </>
  );

  return (
    <PageHeader.Container>
      <PageHeader.TabsContainer
        className={clsx({ [Styles.tabsBorder]: ideaBankEnabled })}
      >
        <StatusToggle />
        <PageHeader.Actions>
          {isIdeaRoute && ideaBankEnabled ? <ScoreToggle /> : null}
          {isDevRoute ? oldDevFilters : null}
          {isPublishedRoute ? publishedFilters : null}
          {ideaBankEnabled ? (
            <>
              <Filters />
              <SortSelect />
            </>
          ) : (
            <>
              <PublishDateFilterOld />
              <OverflowFilters />
              <SortSelectOld />
            </>
          )}
        </PageHeader.Actions>
      </PageHeader.TabsContainer>
      {isIdeaRoute ? (
        <PageHeader.ChipsRow>
          <TagsBar />
        </PageHeader.ChipsRow>
      ) : null}
    </PageHeader.Container>
  );
}
