import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { trackEvent } from '@studio/lib/heap';
import { useParamStore } from '@studio/stores';
import { Button, Icons, Popover } from '@lib/ui';
import { buildMultiplierRangeDisplayString } from '@lib/utils';
import * as Styles from './filters.css';
import { Props as OutliersFilterInputProps } from './inputs/outliers-filter-input';
import { OutliersFilterInput } from './inputs/outliers-filter-input';

type Props = OutliersFilterInputProps & {
  minLabel?: string;
  maxLabel?: string;
};

/**
 * TODO In a future PR we will need to tie max outlier index
 * to data retrieved from the API. Attempting to ascertain the
 * max value strictly from returned data in the UI is extremely fragile.
 */
export function OutliersFilter({
  minId,
  maxId,
  eventNames,
  minLabel = 'Min',
  maxLabel = 'Max',
}: Props) {
  const { params } = useParamStore();
  const { t } = useTranslation();

  const { [minId]: min, [maxId]: max } = params;
  const hasRange = min !== undefined || max !== undefined;
  const outlierRange = buildMultiplierRangeDisplayString(
    min,
    max,
    minLabel,
    maxLabel
  );

  return (
    <Popover.Root
      onOpenChange={(open) => open && trackEvent(eventNames.opened)}
    >
      <Popover.Trigger asChild>
        <Button
          variant="subtle"
          size="sm"
          adornmentEnd={<Icons.ChevronDownIcon aria-hidden />}
        >
          <span>
            {t('Outlier Index')}:{' '}
            <span className={clsx({ [Styles.primaryText]: hasRange })}>
              {outlierRange}
            </span>
          </span>
        </Button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content align="start" className={Styles.popoverContent}>
          <Popover.Close />
          <OutliersFilterInput
            eventNames={eventNames}
            minId={minId}
            maxId={maxId}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
